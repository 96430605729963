/* Styling for the Impresa component */
.mb-3 {
    margin-bottom: 1rem;
    border: 1px; 
    border-color: black;
  }
  
  /* Row styling */
  .row {
    margin-bottom: 1rem;
  }
  
  /* Label styling */
  label {
    font-weight: bold;
  }
  
  /* Input and select styling */
  input, select {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  
  /* Button styling */
  .btn {
    display: inline-block;
    font-weight: 400;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #007bff;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  
  /* Button color on hover */
  .btn:hover {
    background-color: #0056b3;
    border-color: #0041a2;
  }
  
  /* Different styling for alternating Impresa components */
  .impresa:nth-child(odd) {
    background-color: #f8f9fa;
  }
  
  .impresa:nth-child(even) {
    background-color: #e9ecef;
  }

.mb-3{
    border: 3px solid white;
    border-radius: 10px;
    padding-bottom: 20px;
}


