.cantiere-sidebar {
    background-color: #aeb1c6b6;
    padding-left: 7px;
    height: 70%;
    width :50%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    font-family: sans-serif;
    font-size: 14;
    font-weight: bold;
    margin-bottom: 5px;
  }
.form-group label, input,select {
    color: white;
    font-weight: bold;
  }
  .form-group select {
    background-color:  #8abcce;
    width: 100%;
    font-family: sans-serif;
    border-radius: 5px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .cantiere-sidebar-button {
    display: block;
    width: 60%;
    height: 70%;
    font-family: sans-serif;
    font-size: 14;
    background-color: #aeb3db;
    color: white;
    border: none;
    border-radius: 15px;
    padding-left: 20px;
    padding: 10px;
    padding-top: 20;
    cursor: pointer;
    margin-top: 5px;
    border: 1px solid #ccc;
  }
  
  .cantiere-sidebar-button:hover {
    background-color: #b17650;
  }