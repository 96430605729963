.verifica-gestione-attivita {
    background-color: #625e7d; /* Sostituisci con il colore desiderato */
    padding: 20px;
  }
.verifica-gestione-attivita {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="checkbox"] {
    margin-right: 5px;
  }
  
  input[type="text"] {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
  }
  
  select {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
  }
  
  p {
    margin-top: 20px;
  }