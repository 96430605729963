@keyframes blinkingEffect {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }
  
  .blinking {
    animation: blinkingEffect 1s infinite;
    border: 3px solid orange; /* Aggiungi un bordo rosso per evidenziare ulteriormente */
  }


  .prova{
    background-color:#39414C;
  }