.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: url("/immagini/prova.jpg");
}

.App-link {
  color: #e2e8ea;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.container {
  display: flex;
  height: 100vh; /* Altezza dell'intera finestra */
}

.sidebar {
  width: 100px; /* Larghezza fissa della sidebar */
  background-color: #f4f4f4;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Ombra per evidenziare la sidebar */
  overflow: auto; /* Gestisci eventuali contenuti che superano l'altezza del contenitore */
}
.map {
  flex-grow: 1; /* Occupa tutto lo spazio rimanente */
  display: flex;
  justify-content: center; /* Allinea la mappa orizzontalmente al centro */
  align-items: center; /* Allinea la mappa verticalmente al centro */
  height: 100%; /* Altezza del contenitore della mappa, gestito dal CSS globale */
}


/* Stili per il footer */
footer {
  background-color: #3f51b5; /* Colore di sfondo del footer */
  color: white; /* Colore del testo */
  text-align: center; /* Allinea il testo al centro */
  padding: 10px; /* Padding del footer */
  position: relative;
  width: 100%; /* Assicura che il footer occupi tutta la larghezza */
}