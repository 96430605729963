.filtro-bar {
    display: flex;
    gap: 10px;
    padding: 10px;
    background-color: #a181ca;
    
    border-radius: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
