
.body
{
  background-color: bisque;  
}
.Container{
    background-image: url('/immagini/sfondo.png');
    background-size: cover;
    background-position: center;
    height: 100vh; /* Altezza dello schermo */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white; /* Colore del testo */
  }



