.ispezione {
  margin: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ispezione table {
  width: 100%;
  border-collapse: collapse;
}

.ispezione th, .ispezione td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.ispezione th {
  background-color: #f0f0f0;
}

.ispezione td {
  background-color: #fff;
}

.ispezione td:nth-child(2) {
  text-align: center;
}

.ispezione td:nth-child(3) {
  text-align: center;
}

.ispezione td:nth-child(4) {
  text-align: center;
}

.ispezione .MuiTableContainer-root {
  overflow-x: auto;
}

.ispezione .MuiTable-root {
  min-width: 650px;
}

.ispezione .MuiTableCell-root {
  padding: 10px;
}