.date-picker-input {
    width: 100%;
    margin-bottom: 10px;
    color: black; /* Colore del testo */
    background-color: white; /* Colore di sfondo */
    border: 1px solid #ccc; /* Aggiungi un bordo per migliorare la visibilità */
    padding: 10px; /* Aggiungi un po' di padding */
    border-radius: 4px; /* Angoli arrotondati */
  }
  
  /* Personalizza il placeholder */
  .date-picker-input::placeholder {
    color: gray; /* Colore del testo del placeholder */
  }