/* Set the background color for the entire map container */
.pippo {
  background-color: #0e1e35; 
  height: 100vh;
  display: flex;
  background-color: url("immagini/sfondo.png");
}

/* Style the sidebar (CantiereSiderbar) */
.CantiereSiderbar {
  background-color:  #173249;  /* Light yellow */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);  /* Subtle shadow */
  color: #173249;  /* Dark text */
  padding: 10px;  /* Add some padding */
}

/* Style the map container within the MapContainer */
.leaflet-container {
  border: 2px solid #007bff;  /* Blue border */
  border-radius: 5px;  /* Rounded corners */
}

/* Style the map tiles (streets, buildings, etc.) */
.leaflet-tile {
  filter: hue-rotate(120deg);  /* Apply a green tint */
}

/* Style the marker icons */


/* Style the popup content */
.leaflet-popup-content-wrapper {
  background-color: #fff;  /* White background */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);  /* Stronger shadow */
  color: #000;  /* Black text */
  padding: 5px;  /* Adjust padding */
  font-weight: bold;  /* Bold text */
}

.cantiere-sidebar {
  background-color: #050922b6;
  padding-left: 10px;
  height: 80vh;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0; /* Prevent shrinking */
  min-width: 200px; /* Optional: Set a minimum width */  
  display: flex;

}




.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-family: sans-serif;
  font-size: 14;
  font-weight: bold;
  margin-bottom: 5px;
}
.form-group label, input,select {
  color: white;
  font-weight: bold;
}
.form-group select {
  background-color:  #3d616e;
  width: 70%;
  font-family: sans-serif;
  border-radius: 5px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.cantiere-sidebar-button {
  display: flex;
  width: 70%;
  height: 80%;
  font-family: sans-serif;
  font-size: 14;
  background-color: #3d616e;
  color: white;
  border: none;
  border-radius: 5px;
  padding-left: 10px;
  padding-top: 20;
  cursor: pointer;
  margin-top: 5px;
  border: 2px solid #ccc;
}

.cantiere-sidebar-button:hover {
  background-color: #23a0da;
}

